var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

(function (root, factory) {
  if (exports) {
    exports = factory();
  } else {
    root.Autogrow = factory();
  }
})(exports, function () {
  return function (textarea, maxLines) {
    var self = this || _global;

    if (maxLines === undefined) {
      maxLines = 999;
    }
    /**
     * Calculates the vertical padding of the element
     * @param textarea
     * @returns {number}
     */


    self.getOffset = function (textarea) {
      var style = window.getComputedStyle(textarea, null),
          props = ["paddingTop", "paddingBottom"],
          offset = 0;

      for (var i = 0; i < props.length; i++) {
        offset += parseInt(style[props[i]]);
      }

      return offset;
    };
    /**
     * Sets textarea height as exact height of content
     * @returns {boolean}
     */


    self.autogrowFn = function () {
      var newHeight = 0,
          hasGrown = false;

      if (textarea.scrollHeight - offset > self.maxAllowedHeight) {
        textarea.style.overflowY = "scroll";
        newHeight = self.maxAllowedHeight;
      } else {
        textarea.style.overflowY = "hidden";
        textarea.style.height = "auto";
        newHeight = textarea.scrollHeight - offset;
        hasGrown = true;
      }

      textarea.style.height = newHeight + "px";
      return hasGrown;
    };

    var offset = self.getOffset(textarea);
    self.rows = textarea.rows || 1;
    self.lineHeight = textarea.scrollHeight / self.rows - offset / self.rows;
    self.maxAllowedHeight = self.lineHeight * maxLines - offset; // Call autogrowFn() when textarea's value is changed

    textarea.addEventListener("input", self.autogrowFn);
  };
});

export default exports;